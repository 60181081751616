<template>
    <b-modal
      id="glucose-pregnancy-modal"
      ref="glucose-pregnancy-modal"
      :title="form.glucoseBoundaryName"
      @show="openGlucosePopup"
      @hidden="resetModal"
      @ok="submit"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-0">
          <form ref="form" @submit="submit">
            <b-row>
              <b-col>
                <label for="veryLow">Rất Thấp</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <basic-select
                  name="veryLowSelect"
                  :options="isGlucoseTypeOpts"
                  :value.sync="form.veryLow.operator"
                  :solid="false"
                  label=""
                  :allowEmpty="false"
                />
              </b-col>
              <b-col>
                <basic-input
                  name="veryLowInput"
                  inputType="number"
                  :value.sync="form.veryLow.value"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label for="low">Thấp</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <basic-select
                  name="lowSelect"
                  :options="isGlucoseTypeOpts"
                  :value.sync="form.low.operator"
                  :solid="false"
                  label=""
                  :allowEmpty="false"
                />
              </b-col>
              <b-col>
                <basic-input
                  name="lowInput"
                  inputType="number"
                  :value.sync="form.low.value"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label for="normal">Tốt</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <basic-select
                  name="normalSelect"
                  :options="isGlucoseTypeOpts"
                  :value.sync="form.normal.operator"
                  :solid="false"
                  label=""
                  :allowEmpty="false"
                />
              </b-col>
              <b-col>
                <basic-input
                  name="normalInput"
                  inputType="number"
                  :value.sync="form.normal.value"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label for="veryHigh">Rất Cao</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <basic-select
                  name="veryHighSelect"
                  :options="isGlucoseTypeOpts"
                  :value.sync="form.veryHigh.operator"
                  :solid="false"
                  label=""
                  :allowEmpty="false"
                />
              </b-col>
              <b-col>
                <basic-input
                  name="veryHighInput"
                  inputType="number"
                  :value.sync="form.veryHigh.value"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label for="high">Cao</label>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <basic-select
                  name="highSelect"
                  :options="isGlucoseTypeOpts"
                  changeValueByObject
                  track-by="operator"
                  label=""
                  :value.sync="form.high.operator"
                />
              </b-col>
              <b-col>
                <basic-input
                  name="highInput"
                  inputType="number"
                  :value.sync="form.high.value"
                ></basic-input>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button class="btn btn-success ml-2" type="submit" @click="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Cập nhật
          </b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  export default {
    components: {},
    props: {
      popupType: {
        type: String,
        default: 'detail',
      },
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        loading: false,
        form: {
          glucoseBoundaryKey: null,
          glucoseBoundaryName: null,
          low: {
            operator: null,
            value: null,
          },
          veryLow: {
            operator: null,
            value: null,
          },
          normal: {
            operator: null,
            value: null,
          },
          high: {
            operator: null,
            value: null,
          },
          veryHigh: {
            operator: null,
            value: null,
          },
        },
        validationState: {
          glucoseBoundaryKey: null,
          glucoseBoundaryName: null,
          low: null,
          veryLow: null,
          normal: null,
          high: null,
          veryHigh: null,
        },
        error: {
          glucoseBoundaryKey: null,
          glucoseBoundaryName: null,
          low: null,
          veryLow: null,
          normal: null,
          high: null,
          veryHigh: null,
        },
        isGlucoseTypeOpts: [
          { id: 1, name: 'Nhỏ hơn (<)' },
          { id: 2, name: 'Lớn hơn (>)' },
          { id: 3, name: 'Nhỏ hơn hoặc bằng (<=)' },
          { id: 4, name: 'Lớn hơn hoặc bằng (>=)' },
        ],
      };
    },
    computed: {
      glucoseData: {
        get() {
          let newGlucose = {
            glucoseBoundaryKey: this.form.glucoseBoundaryKey,
            glucoseBoundaryName: this.form.glucoseBoundaryName,
            low: this.form.low,
            veryLow: this.form.veryLow,
            normal: this.form.normal,
            high: this.form.high,
            veryHigh: this.form.veryHigh,
          };
          return newGlucose;
        },
  
        set(data) {
          this.form.glucoseBoundaryKey = data.glucoseBoundaryKey;
          this.form.glucoseBoundaryName = data.glucoseBoundaryName;
          this.form.low = data.low;
          this.form.low.operator = this.isGlucoseTypeOpts.find(
            (e) => e.id === data.low.operator,
          );
          this.form.veryLow = data.veryLow;
          this.form.veryLow.operator = this.isGlucoseTypeOpts.find(
            (e) => e.id === data.veryLow.operator,
          );
          this.form.normal = data.normal;
          this.form.normal.operator = this.isGlucoseTypeOpts.find(
            (e) => e.id === data.normal.operator,
          );
          this.form.high = data.high;
          this.form.high.operator = this.isGlucoseTypeOpts.find(
            (e) => e.id === data.high.operator,
          );
          this.form.veryHigh = data.veryHigh;
          this.form.veryHigh.operator = this.isGlucoseTypeOpts.find(
            (e) => e.id === data.veryHigh.operator,
          );
        },
      },
    },
    methods: {
      resetModal() {
        this.form = {
          glucoseBoundaryKey: null,
          glucoseBoundaryName: null,
          low: {
            operator: null,
            value: null,
          },
          veryLow: {
            operator: null,
            value: null,
          },
          normal: {
            operator: null,
            value: null,
          },
          high: {
            operator: null,
            value: null,
          },
          veryHigh: {
            operator: null,
            value: null,
          },
        };
      },
      transformSelect(payload) {
        let data = { ...payload };
        let form = {
          ...data,
          high: {
            operator: data.high.operator?.id
              ? data.high.operator.id
              : data.high.operator,
            value: data.high.value,
          },
          low: {
            operator: data.low.operator?.id
              ? data.low.operator.id
              : data.low.operator,
            value: data.low.value,
          },
          normal: {
            operator: data.normal.operator?.id
              ? data.normal.operator.id
              : data.normal.operator,
            value: data.normal.value,
          },
          veryHigh: {
            operator: data.veryHigh.operator?.id
              ? data.veryHigh.operator.id
              : data.veryHigh.operator,
            value: data.veryHigh.value,
          },
          veryLow: {
            operator: data.veryLow.operator?.id
              ? data.veryLow.operator.id
              : data.veryLow.operator,
            value: data.veryLow.value,
          },
        };
        return form;
      },
      loadGlucoseData() {
        this.loading = true;
        this.$api
          .get(`Glucose/Pregnancy/${this.id}`)
          .then(({ data }) => {
            this.glucoseData = data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toastr.e({
              title: 'Lỗi!',
              msg: error,
            });
          });
      },
      isFormValid() {
        let isValid = true;
        for (let field of Object.keys(this.form)) {
          let validationFunction = `${field}Validation`;
          if (
            this[validationFunction] &&
            typeof this[validationFunction] === 'function'
          ) {
            this[validationFunction]();
          }
        }
  
        for (let validate of Object.keys(this.validationState)) {
          if (this.validationState[validate] === false) {
            isValid = false;
            break;
          }
        }
        return isValid;
      },
      edit() {
        let form = this.transformSelect(this.glucoseData);
        this.$store.commit('context/setLoading', true);
        this.$api
          .put('Glucose/UpdateGlucosePregnancy', form)
          .then(() => {
            this.$emit('reloadGlucose');
            this.$nextTick(() => {
              this.$bvModal.hide('glucose-pregnancy-modal');
            });
          })
          .catch((error) => {
            if (error) {
              this.$toastr.e({
                title: 'Lỗi !',
                msg: 'Cập nhật thất bại !',
              });
            }
          })
          .finally(() => {
            this.$store.commit('context/setLoading', false);
          });
      },
      submit() {
        // bvModalEvt.preventDefault();
        let isFormValid = this.isFormValid();
        if (isFormValid) {
          this.edit();
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
        return;
      },
      openGlucosePopup() {
        if (this.id) {
          this.loadGlucoseData();
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  #glucose-modal {
    .modal-dialog {
      width: 410px;
      height: 582px;
    }
  }
  </style>
  